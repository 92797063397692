import $ from 'jquery';
import 'what-input';
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';
import anime from 'animejs/lib/anime.es.js';



// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require('paroller.js');
$(document).foundation();

const navTrigger = document.querySelector('.js-navTrigger');
const closeTrigger = document.querySelector('.js-closeTrigger');
const navElement = document.querySelector('.js-mainNav');
const bodyElement = document.querySelector('body');
navTrigger.addEventListener('click', function(e){
    console.log(this.offsetLeft);
    navElement.classList.toggle('active');
    bodyElement.classList.toggle('hide-overflow');
    closeTrigger.style.left = `${this.offsetLeft}px`;
    closeTrigger.style.top = `${this.offsetTop}px`;
});
closeTrigger.addEventListener('click', function(e){
    navElement.classList.toggle('active');
    bodyElement.classList.toggle('hide-overflow');
});

if ( $( ".hero-text" ).length ) {
const textWrapper = document.querySelectorAll('.hero-text');
const textWrapperInverse = document.querySelectorAll('.hero-text.inverse');
textWrapper.forEach(textItem => {
    textItem.innerHTML = textItem.textContent.replace(/\S/g, "<span class='letter regular-letter'>$&</span>")
    textItem.style.opacity = '1';
});
textWrapperInverse.forEach(textItem => {
    textItem.innerHTML = textItem.textContent.replace(/\S/g, "<span class='letter inverse-letter'>$&</span>")
    textItem.style.opacity = '1';
});

const decorationLink = document.querySelector('.hero-content .decorative-line');
function animateDecoration() {
    decorationLink.classList.add('animate');
}
setTimeout(animateDecoration, 2300);

anime.timeline({loop: false})
  .add({
    targets: '.hero-text-wrapper .regular .regular-letter',
    translateX: [40,0],
    translateY: [10,0],
    translateZ: 0,
    opacity: [0,1],
    easing: "easeOutExpo",
    duration: 1500,
    delay: (el, i) => 500 + 30 * i
  })
  .add({
    targets: '.hero-text-wrapper .inverse .inverse-letter',
    translateX: [40,0],
    translateY: [10,0],
    translateZ: 0,
    opacity: [0,1],
    easing: "easeOutExpo",
    duration: 1500,
    delay: anime.stagger(25, {direction: 'reverse'})
  }, 1200);
}

if ( $( ".partners-section" ).length ) {
    var swiper = new Swiper(".partners-section", {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        slidesPerGroup: 1,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // }
      });
}

if ( $( ".people-section" ).length ) {
    var swiper = new Swiper(".people-section", {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        slidesPerGroup: 1,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // }
      });
}

$('.js-parollerElement').paroller({
    factor: 0.1,            // multiplier for scrolling speed and offset
    type: 'foreground',     // background, foreground
    direction: 'vertical', // vertical, horizontal
    transition: 'transform 0.2s ease' // CSS transition
});

/*

const elements = document.querySelectorAll('.decoration-wrapper');
let data,
    moreData;
let ticking = false;
var lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

elements.forEach(element => {
    element.style.transform = 'translateY(80px)';
    // console.log(element.style);
});

function doSomething(theElement, upwardsDirection) {
    // console.log(theElement.style);
    let newOffset;
    const {x, y, z} = getTranslateValues(theElement);
    const yOffset = parseInt(y);
    if (upwardsDirection) {
        newOffset = yOffset - yOffset*0.1;
    } else {
        newOffset = yOffset + yOffset*0.1;
    }
    theElement.style.transform = `translateY(${newOffset}px)`;
}

document.addEventListener("scroll", (event) => {
    if (!ticking) {
        window.requestAnimationFrame(() => {
            elements.forEach(elm => {
                if (isInViewport(elm)) {
                    getScrollingDirection(elm);
                }
            })
            ticking = false;
        });
        ticking = true;
    }
});

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function getTranslateValues (element) {
    const style = window.getComputedStyle(element)
    const matrix = style['transform'] || style.webkitTransform || style.mozTransform
  
    // No transform property. Simply return 0 values.
    if (matrix === 'none' || typeof matrix === 'undefined') {
      return {
        x: 0,
        y: 0,
        z: 0
      }
    }
  
    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d'
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')
  
    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
      return {
        x: matrixValues[4],
        y: matrixValues[5],
        z: 0
      }
    }
  
    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
      return {
        x: matrixValues[12],
        y: matrixValues[13],
        z: matrixValues[14]
      }
    }
  }

var lastScrollTop = 0;

function getScrollingDirection(elm) {
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    let scrollIsUp;
    if (st > lastScrollTop) {
        scrollIsUp = 0;
        doSomething(elm, scrollIsUp);
    } else if (st < lastScrollTop) {
        scrollIsUp = 1;
        doSomething(elm, scrollIsUp);
    } // else was horizontal scroll
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}

*/